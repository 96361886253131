<template>
  <div>
    <a-button-group>
      <a-popconfirm title="确定同意吗" @confirm="agree">
        <a-button type="primary" :disabled="item.status !== 'pending'" size="small">同意</a-button>
      </a-popconfirm>
      <a-popconfirm title="确定拒绝吗" @confirm="refuse">
        <a-button type="danger" :disabled="item.status !== 'pending'" size="small">拒绝</a-button>
      </a-popconfirm>
    </a-button-group>
  </div>
</template>

<script>
import { reviewAgree, reviewRefuse } from "@/apis/data";

export default {
  props: ["item"],
  data() {
    return {};
  },
  methods: {
    agree() {
      reviewAgree({ id: this.item.id }).then((data) => {
        this.$message.success("同意成功");
        this.$emit("update", data);
      });
    },
    refuse() {
      reviewRefuse({ id: this.item.id }).then((data) => {
        this.$message.success("拒绝成功");
        this.$emit("update", data);
      });
    },
    update(item) {
      this.$emit("update", item);
    },
  },
};
</script>

<style scoped></style>
